import React from 'react';

import DepartureIcon from 'assets/images/flight-departure.svg';
import ArrivalIcon from 'assets/images/flight-arrival.svg';

import { formatTime } from '@/utils/helpers';
import { KiuFlightType } from "@/graphql/types";
import { StyledWrapper, StyledItineraryContainer, Divider } from './FlightItineraries.styles';
import FlightDetail from './FlightDetail';

interface Props {
    itineraries: Array<KiuFlightType>
}

const FlightItinerary: React.FC<Props> = ({ itineraries }) => {
  return (
    <StyledWrapper>
      <StyledItineraryContainer>
        {itineraries.map((itinerary, index) => {
          const carrierDetail = `${itinerary.carrier?.name} ${itinerary.carrier?.designation} ${itinerary.flightNumber}`;

          return (
            <div key={index}>
              {index > 0 && <Divider />}
              
              <FlightDetail
              icon={<DepartureIcon />}
              cityCode={itinerary.departingCity.code}
              cityName={itinerary.departingCity.name}
              datetime={itinerary.departingDatetime}
              carrierDetail={carrierDetail}
              duration={formatTime(itinerary.duration)}
              />
              
              <FlightDetail
              icon={<ArrivalIcon />}
              cityCode={itinerary.arrivingCity.code}
              cityName={itinerary.arrivingCity.name}
              datetime={itinerary.arrivingDatetime}
              />
            </div>
          );
        })}
      </StyledItineraryContainer>
    </StyledWrapper>
  );
};

export default FlightItinerary;
