import React from 'react';
import Moment from 'react-moment';
import { secondary } from '@/styles/colors';
import { useTranslation } from 'next-i18next';
import { formatTime } from '@/utils/helpers';
import { Typography, FlightLine, Button, ModalDialog } from '@/components/atoms';
import { FlightCardProps } from './FlightCard.types';
import { Wrapper, Route, Departure, Scale, Arrival, Details, Separator } from './FlightCard.styles';
import FlightItineraries from '../FlightItinearies';

const FlightCard: React.FC<FlightCardProps> = ({ active, itineraries, origin, departureTime, destination, arrivalTime, stops, duration, onChoose }) => {
    const [showDetail, setShowDetail] = React.useState(false)

    const { t } = useTranslation();

    return (
        <Wrapper active={active}>
            <Route>
                <Departure>
                    <Typography variant="h5" align="right">
                        <Moment format="HH:mm" parse="YYYY-MM-DDTHH:mm:ss" date={departureTime} />
                    </Typography>
                    <Typography variant="body1" align="right">
                        {origin}
                    </Typography>
                </Departure>

                <Scale>
                    <Typography variant="sub2" align="center">
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                        {formatTime(duration)}
                    </Typography>
                    <FlightLine steps={stops} display="horizontal" variant="normal" />
                    <Typography variant="body2" color={secondary.secondary50} align="center">
                        {stops === 0 ? t('flights:stops.non-stop-flight') : stops === 1 ? t('flights:stops.stop-flight') : t('flights:stops.stop-flight_plural', { stops })}
                    </Typography>
                </Scale>

                <Arrival>
                    <Typography variant="h5">
                        <Moment format="HH:mm" parse="YYYY-MM-DDTHH:mm:ss" date={arrivalTime} />
                    </Typography>
                    <Typography variant="body1">{destination}</Typography>
                </Arrival>
            </Route>
            <Separator />
            <Details>
                <Button variant="outlined" content={t('flights:view-details.button')} onClick={()=>setShowDetail(true)}/>
                <Button variant="filled" content={t('flights:choose.button')} onClick={onChoose} />
            </Details>

            <ModalDialog width={450} isOpen={showDetail} onClose={()=>setShowDetail(false)}>
                <FlightItineraries itineraries={itineraries || []} />
            </ModalDialog>
        </Wrapper>
    );
};

export default FlightCard;
