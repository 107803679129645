import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { generateUniqueCode } from '@/utils/helpers';
import { useWizard } from '@/modules/wizard';
import { useFilterSearch, useTripType } from '@/modules/persistent-states';
import { FlightCard, FlightCardSkeleton, FlightsDateSelector } from '@/components/molecules';
import { KiuDateType, KiuRouteType } from '@/graphql/types';
import { FlightsScheduleProps, DateSelectorType } from './FlightsSchedule.types';
import { Wrapper, InnerWrapper, Flights, FlightsHeader, Card } from './FlightsSchedule.styles';
import { Typography } from '@/components/atoms';
import * as FeatherIcon from "react-feather";

// TODO: FlightFilters section
// const FlightFilters = dynamic(() => import('@/components/molecules/FlightFilters'), {
//     ssr: false,
// });

const FlightsSchedule: React.FC<FlightsScheduleProps> = ({ direction, flights, loading }) => {
    const [savedDates, setSavedDates] = React.useState<DateSelectorType[]>();
    const [selectedDate, setSelectedDate] = React.useState<string>('');
    const [routesExist, setRoutesExist] = React.useState<boolean>(false);

    const { t } = useTranslation(); // Translations
    
    const { state, dispatch } = useWizard();

    const [filterSearch, setFilterSearch] = useFilterSearch();

    const [tripType] = useTripType();

    const routes = useMemo(() => {
        if (direction === 'origin-destination') {
            return flights?.outboundRoutes as KiuDateType[];
        } else {
            return flights?.returnRoutes as KiuDateType[];
        }
    }, [direction, flights]);

    

    const dates = routes?.map((flight) => {
        return {
            date: flight?.date as string,
            status: flight?.isValid,
            minValue: 0,
        };
    });

    React.useEffect(() => {
        if (!loading) {
            setSavedDates(dates);

            if (direction === 'origin-destination') {
                setSelectedDate(flights?.departingDate as string);
            } else {
                setSelectedDate(flights?.returnDate as string);
            }
        }
    }, [loading]);

    const handleOnSelected = (selected: string) => {
        let newFilter;

        setSelectedDate(selected);

        if (direction === 'origin-destination') {
            newFilter = {
                ...filterSearch,
                departure_date: selected,
            };
        } else {
            newFilter = {
                ...filterSearch,
                return_date: selected,
            };
        }

        setFilterSearch(newFilter);
    };

    // TODO: FlightFilters section
    // const stopsFilters = [
    //     {
    //         id: 'non-stop',
    //         label: t('flights:filters.stops.non-stop'),
    //         condition: () => filterSearch?.maxStopsQty === 1,
    //         onSelect: () => {
    //             setFilterSearch({
    //                 ...filterSearch,
    //                 maxStopsQty: 1,
    //             });
    //         },
    //     },
    //     {
    //         id: 'one-stop',
    //         label: t('flights:filters.stops.one-stop'),
    //         condition: () => filterSearch?.maxStopsQty === 2,
    //         onSelect: () => {
    //             setFilterSearch({
    //                 ...filterSearch,
    //                 maxStopsQty: 2,
    //             });
    //         },
    //     },
    //     {
    //         id: 'more-stop',
    //         label: t('flights:filters.stops.more-stop'),
    //         condition: () => (filterSearch?.maxStopsQty ? filterSearch?.maxStopsQty > 2 : false),
    //         onSelect: () => {
    //             setFilterSearch({
    //                 ...filterSearch,
    //                 maxStopsQty: 4,
    //             });
    //         },
    //     },
    // ];

    // const cabinFilters = [
    //     {
    //         id: 'economy-class',
    //         label: t('flights:filters.cabin.economy-class'),
    //         condition: () => filterSearch?.cabin === 'ECONOMY',
    //         onSelect: () => {
    //             setFilterSearch({
    //                 ...filterSearch,
    //                 cabin: 'ECONOMY',
    //             });
    //         },
    //     },
    //     {
    //         id: 'business-class',
    //         label: t('flights:filters.cabin.business-class'),
    //         condition: () => filterSearch?.cabin === 'BUSINESS',
    //         onSelect: () => {
    //             setFilterSearch({
    //                 ...filterSearch,
    //                 cabin: 'BUSINESS',
    //             });
    //         },
    //     },
    //     {
    //         id: 'first-class',
    //         label: t('flights:filters.cabin.first-class'),
    //         condition: () => filterSearch?.cabin === 'FIRST',
    //         onSelect: () => {
    //             setFilterSearch({
    //                 ...filterSearch,
    //                 cabin: 'FIRST',
    //             });
    //         },
    //     },
    // ];

    const handleOnChoose = (route: KiuRouteType) => {
        if (direction === 'origin-destination') {
            dispatch({
                type: 'UPDATE_DATA',
                payload: {
                    outboundRouteSelected: route,
                },
            });
        } else {
            dispatch({
                type: 'UPDATE_DATA',
                payload: {
                    returnRouteSelected: route,
                },
            });
        }

        if(tripType === 'one-way') {
            dispatch({ type: 'GO_TO_STEP', payload: 2 });
            return;
        }

        dispatch({ type: 'NEXT_STEP' });
    };

    const validScheduledRoutes = useMemo(() => {
        const validRoutes = routes
            ?.filter((route) => route.date === selectedDate && route.isValid)
            ?.map((route: KiuDateType) => {
                const subRoutes = route.routes?.filter((subRoute) => subRoute?.isValid && !subRoute?.errors);

                return subRoutes?.map((subRoute, index) => {
                    let savedKey;

                    if (direction === 'origin-destination') {
                        savedKey = generateUniqueCode(
                            `${state.data?.outboundRouteSelected?.airlines?.join(',')}>${state?.data?.outboundRouteSelected?.origin.code}-${state?.data
                                ?.outboundRouteSelected?.destination.code} ${state?.data?.outboundRouteSelected?.departingDatetime}-${state?.data
                                ?.outboundRouteSelected?.arrivingDatetime}-${index}`
                        );
                    } else {
                        savedKey = generateUniqueCode(
                            `${state.data?.returnRouteSelected?.airlines?.join(',')}>${state?.data?.returnRouteSelected?.origin.code}-${state?.data
                                ?.returnRouteSelected?.destination.code} ${state?.data?.returnRouteSelected?.departingDatetime}-${state?.data
                                ?.returnRouteSelected?.arrivingDatetime}-${index}`
                        );
                    }

                    const key = generateUniqueCode(
                        `${subRoute?.airlines?.join(',')}>${subRoute?.origin.code}-${subRoute?.destination
                            .code} ${subRoute?.departingDatetime}-${subRoute?.arrivingDatetime}-${index}`
                    );

                    const itineraries = (subRoute?.flights || []).filter((flight) => flight !== null);

                    return (
                        <FlightCard
                            key={key}
                            itineraries={itineraries}
                            departureTime={subRoute?.departingDatetime}
                            origin={subRoute?.origin.code as string}
                            arrivalTime={subRoute?.arrivingDatetime}
                            destination={subRoute?.destination.code as string}
                            stops={subRoute?.stopsQty as number}
                            duration={subRoute?.duration}
                            active={key === savedKey}
                            onChoose={() => handleOnChoose(subRoute as KiuRouteType)}
                        />
                    );
                });
            });

        setRoutesExist(validRoutes?.length > 0);
        return validRoutes;
    }, [routes, selectedDate, direction, state]);

    return (
        <Wrapper>
            <FlightsDateSelector dates={savedDates} selectedDate={selectedDate} onSelected={handleOnSelected} loading={loading} />

            <InnerWrapper>
                {/* TODO: FlightFilters section */}
                {/* <Filters>
                    <FlightFilters title={t('flights:filters.stops.title')} filters={stopsFilters} />
                    <FlightFilters title={t('flights:filters.cabin.title')} filters={cabinFilters} />
                </Filters> */}
                <Flights>
                    <FlightsHeader></FlightsHeader>

                    {loading && (
                        <>
                            <FlightCardSkeleton />
                            <FlightCardSkeleton />
                            <FlightCardSkeleton />
                        </>
                    )}

                    {!loading && !routesExist && (
                        <Card>
                            <FeatherIcon.AlertTriangle size={60} strokeWidth={2} />
                            <Typography variant="body1" align="center" weight={700} mb={10}>
                                {t('flights:routes.label.sorry')}
                            </Typography>
                            <Typography variant="body1" align="center">
                                {t('flights:routes.label.no_routes')}
                            </Typography>
                        </Card>
                    )}

                    {!loading && validScheduledRoutes}
                </Flights>
            </InnerWrapper>
        </Wrapper>
    );
};

export default FlightsSchedule;
