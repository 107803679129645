import styled, { createGlobalStyle, css } from "styled-components";

// for disabling scrolling when modal is open
export const GlobalStyle = createGlobalStyle`
  body.modal-open {
    overflow: hidden;
  }
`;

const overlayTransition = css`
  transition: opacity 0.3s ease;
`;

export const Overlay = styled.div<{ isOpen: boolean; visible: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  ${overlayTransition}
`;

const contentTransition = css`
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

export const Content = styled.div<{ visible: boolean, width?:number }>`
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: ${({ width }) => width ? `${width}px` : 'auto'};

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.sm}px) {
      width: 100%;
    }
  `}

  transform: ${({ visible }) => (visible ? 'translateY(0)' : 'translateY(-20px)')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  ${contentTransition}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;