import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'next-i18next';
import Moment from 'react-moment';
import { Navigation, Keyboard, Thumbs, FreeMode, EffectFade } from 'swiper/modules';
import { Skeleton, Typography } from '@/components/atoms';
import { FlightsDateSelectorProps } from './FlightsDateSelector.types';
import { CardDateFlights, Slideshow, SwiperGlobalStyles } from './FlightsDateSelector.styles';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/effect-fade';

const FlightsDateSelector: React.FC<FlightsDateSelectorProps> = ({ dates, selectedDate, onSelected, loading }) => {
    const { t } = useTranslation();

    const handleSelected = (date: string) => {
        onSelected(date);
    };
    
    return (
        <Slideshow>
            <SwiperGlobalStyles />
            <Swiper
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    992: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1366: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                }}
                keyboard
                navigation
                modules={[Keyboard, Navigation, Thumbs, FreeMode, EffectFade]}
                className="FlightsDateSelector"
            >
                {loading &&
                    [1, 2, 3, 4, 5].map((item) => {
                        return (
                            <SwiperSlide key={item}>
                                <Skeleton height={4.7} radius={12} />
                            </SwiperSlide>
                        );
                    })}
                {!loading &&
                    dates?.map((item) => {
                        return (
                            <SwiperSlide key={item.date} onClick={() => item.status && handleSelected(item.date)}>
                                <CardDateFlights isActive={item.date === selectedDate} disabled={!item.status}>
                                    <Typography variant="sub1" align="center" uppercase style={{ whiteSpace: 'nowrap' }}>
                                        <Moment format="ddd. DD MMM.">{item.date}</Moment>
                                    </Typography>
                                    <Typography variant="body2" align="center">
                                        {/*{item.minValue && item.minValue > 0 ? `${t('flights:date-selector.since')} $${item.minValue}` : t('flights:date-selector.no-flights')}*/}
                                        {!item.status && t('flights:date-selector.no-flights')}
                                    </Typography>
                                </CardDateFlights>
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </Slideshow>
    );
};

export default FlightsDateSelector;
