import React, { useMemo } from 'react';
import { RenderContentProps, State } from './Wizard.types';
import BreadcrumbsSection from '@/sections/breadcrumbs';
import { BreadcrumbType } from '@/components/molecules/Breadcrumbs';
import { useTranslation } from 'next-i18next';
import { useWizard } from '@/modules/wizard/index';
import { useTripType } from '../persistent-states';

const renderWizardContent = (state: State) => {
    const currentStepContent = state.steps.find((step) => step.id === state.currentStep)?.content;

    if (React.isValidElement(currentStepContent)) {
        return React.cloneElement(currentStepContent, { ...state.data });
    }

    return null;
};

const RenderWizardContent: React.FC<RenderContentProps> = ({ state }) => {
    const { t } = useTranslation(); // Translations
    const { dispatch } = useWizard();
    const [tripType] = useTripType();

    const content = renderWizardContent(state);

    const steps = useMemo(() => {
        let filteredSteps = [...state.steps];

        // Remove the ReturnFlight step if the tripType is one-way
        if (tripType === 'one-way') {
            filteredSteps = filteredSteps.filter((step) => step.name !== 'ReturnFlight');
        }

        return filteredSteps;
    }, [state.steps, tripType]);

    const breadcrumbs: BreadcrumbType[] = useMemo(() => {
        return steps.map((step, index) => ({
            id: step.id,
            value: `${index + 1}. ${t(step.i18nKey)}`,
            onClick: () => {
                dispatch({
                    type: 'GO_TO_STEP',
                    payload: step.id,
                });
            },
        }));
    }, [steps, t, dispatch]);

    return (
        <>
            <BreadcrumbsSection breadcrumbs={breadcrumbs} bgcolor="default" current={state.currentStep} />
            {content}
        </>
    );
};

export default RenderWizardContent;
