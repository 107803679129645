import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';

interface Props {
    children: React.ReactNode;
    defaultOpen?: boolean; 
}

export interface CollapseHandle {
    toggle: () => void;
    open: () => void;
    close: () => void;
}

const Collapse = forwardRef<CollapseHandle, Props>(({ children, defaultOpen = false }, ref) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const contentRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    toggle: () => setIsOpen((prev) => !prev),
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.maxHeight = '0';
      }
    }
  }, [isOpen]);

  return (
    <div>
      <div
        ref={contentRef}
        style={{
          overflow: 'hidden',
          transition: 'max-height 0.3s ease-in-out',
        }}
      >
        {children}
      </div>
    </div>
  );
});

Collapse.displayName = 'Collapse';

export default Collapse;
