import styled from 'styled-components';
import { Typography } from '@/components/atoms';

export const Block = styled.div`
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    &:first-of-type {
        padding: 0 0 5px 0;
    }
`;

export const LeftCollapseContainer = styled.div`
    margin-top: 8px;
`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    cursor: pointer;
`;

export const RightCollapseContainer = styled.div`
    margin-top: 8px;
`;

export const RightCollapseRow = styled(Typography)`
    width: calc(100% - 28px);
    text-align: right;
`;
