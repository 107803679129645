import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StyledFlightDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledFlightInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

export const StyledFlightInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const StyledFlightInfoCenter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const StyledItineraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  gap: 8px;
`;

export const Divider = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.neutral90};
    height: 1px;
    width: 100%;
    margin: 16px 0;
`;