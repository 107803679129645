import React, { useRef, useState } from 'react';
import Moment from 'react-moment';
import * as FeatherIcon from 'react-feather';

import { useTranslation } from 'next-i18next';
import { Typography, Button, Collapse } from '@/components/atoms';
import { PriceResumeCardProps, ResumeBlockProps } from './PriceResumeCard.types';
import { Block, LeftCollapseContainer, RightCollapseContainer, RightCollapseRow, RightContainer, Row } from './PriceResumeCard.styles';
import { CollapseHandle } from '@/components/atoms/Collapse/Collapse';

const ResumeBlock = <T,>({ title, items, mapItemToProps }: ResumeBlockProps<T>) => {
    return (
        <Block>
            {title && (
                <Typography variant="sub2" style={{ paddingBottom: '10px' }}>
                    {title}
                </Typography>
            )}
            {items?.map((item, index) => {
                const { left, right } = mapItemToProps(item);
                return (
                    <Row key={index}>
                        <Typography variant="body2">{left}</Typography>
                        <Typography variant="body2">{right}</Typography>
                    </Row>
                );
            })}
        </Block>
    );
};

const normalizeValue = (value: string | number): string => {
    if (typeof value === 'string' && !value.includes('NaN')) {
        return value;
    }else  if (Number.isFinite(value)) {
        return value.toString();
    }
    
    return '-';
};

const PriceResumeCard: React.FC<PriceResumeCardProps> = ({ flights, airFare, taxes, feeSummary, total, disabled, hideButton = false, onAccept }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const taxCollapseRefLeft = useRef<CollapseHandle>(null);
    const taxCollapseRefRight = useRef<CollapseHandle>(null);

    const { t } = useTranslation(); // Translations

    const adultAirFare = airFare?.filter((fare) => fare.type === 'Adult' && normalizeValue(fare.value) !== '-') || [];
    const childAirFare = airFare?.filter((fare) => fare.type === 'Child' && normalizeValue(fare.value) !== '-') || [];

    const handleToggleCollapseTax = () => {
        setIsCollapsed(!isCollapsed);

        if (taxCollapseRefLeft.current && taxCollapseRefRight.current) {
            taxCollapseRefLeft.current.toggle();
            taxCollapseRefRight.current.toggle();
        }
    }

    const taxTotal = taxes?.[0];
    
    const detailTaxes = taxes?.slice(1);

    return (
        <>
            <ResumeBlock
                title={t('flights:resume.flights')}
                items={flights}
                mapItemToProps={(item) => ({
                    left: (
                        <>
                            <span>
                                {item.origin.code} · <Moment format="HH:mm" parse="YYYY-MM-DDTHH:mm:ss" date={item.departingDatetime} />
                            </span>
                            {' - '}
                            <span>
                                {item.destination.code} · <Moment format="HH:mm" parse="YYYY-MM-DDTHH:mm:ss" date={item.arrivingDatetime} />
                            </span>
                        </>
                    ),
                    right: (
                        <>
                            <Moment format="ddd, DD MMM, YYYY">{item.departingDatetime}</Moment>
                        </>
                    ),
                })}
            />
            {adultAirFare.length > 0 &&
                <ResumeBlock
                    title={t('flights:passenger.type.adult_plural')}
                    items={adultAirFare}
                    mapItemToProps={(item) => ({
                        left: item.name,
                        right: normalizeValue(item.value),
                    })}
                />
            }
            {childAirFare.length > 0 &&
                <ResumeBlock
                    title={t('flights:passenger.type.child_plural')}
                    items={childAirFare}
                    mapItemToProps={(item) => ({
                        left: item.name,
                        right: normalizeValue(item.value),
                    })}
                />
            }
            <ResumeBlock
                title={t('flights:resume.taxes')}
                items={[taxTotal]}
                mapItemToProps={(item) => ({
                    left: (
                        <>
                            <span>{item?.name}</span>
                            <LeftCollapseContainer>
                                <Collapse ref={taxCollapseRefLeft}>
                                    {detailTaxes?.map((tax, index) => (
                                        <Row key={index}>
                                            <Typography variant="body2">{tax.name}</Typography>
                                        </Row>
                                    ))}
                                </Collapse>
                            </LeftCollapseContainer>
                        </>
                    ),
                    right: (
                        <>
                            {(detailTaxes?.length ?? 0) > 0 && (
                                <RightContainer onClick={handleToggleCollapseTax}>
                                    <span>{normalizeValue(item?.value ?? '')}</span>
                                    {isCollapsed 
                                    ? <FeatherIcon.ChevronDown /> 
                                    : <FeatherIcon.ChevronUp />}
                                </RightContainer>
                            )}
                            <RightCollapseContainer>
                                <Collapse ref={taxCollapseRefRight}>
                                    {detailTaxes?.map((tax, index) => (
                                        <Row key={index}>
                                            <RightCollapseRow variant="body2">{tax.value}</RightCollapseRow>
                                        </Row>
                                    ))}
                                </Collapse>
                            </RightCollapseContainer>
                        </>
                    ),
                })}
            />
            <ResumeBlock
                items={feeSummary}
                mapItemToProps={(item) => ({
                    left: item.name,
                    right: normalizeValue(item.value),
                })}
            />
            <Row style={{ paddingTop: '0' }}>
                <Typography variant="h6">{t('flights:resume.total')}</Typography>
                <Typography variant="h6">{normalizeValue(total)}</Typography>
            </Row>
            {!hideButton && (
                <Row>
                    <Button
                        width="100%"
                        content={t('flights:resume.button.accept-continue')}
                        type="submit"
                        variant="filled"
                        style={{ marginTop: '24px' }}
                        disabled={disabled}
                        onClick={onAccept}
                    />
                </Row>
            )}
        </>
    );
};

export default PriceResumeCard;
