import { DefaultTheme } from 'styled-components';
import * as colors from '@/styles/colors';
import font from '@/styles/fonts';
import media, { BREAKPOINTS } from '@/styles/media';

const theme: DefaultTheme = {
    themeName: 'default',
    font,
    media,
    breakpoints: BREAKPOINTS,
    colors: {
        primary: colors.primary,
        secondary: colors.secondary,
        tertiary: colors.tertiary,
        success: colors.success,
        error: colors.error,
        neutral: colors.neutral,
        basic: colors.basic,
    },
    shadows: {
        shadow1: '0 6px 12px rgba(0 0 0 / 10%)',
    },
    overlays: {
        overlay1: 'linear-gradient(90deg, rgba(10,11,29,1) 0%, rgba(48,57,143,0.4) 60%, rgba(235,235,244,0) 100%)',
        overlay2: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
        overlay3: 'linear-gradient(180deg, rgba(200,200,200,1) 0%, rgba(48,57,143,0.4) 30%, rgba(0,0,0,0) 100%)',
    },
    align: {
        nav: {
            main: {
                mobile: 'left',
                tablet: 'left',
                desktop: 'left',
            },
            sidebar: {
                mobile: 'center',
                tablet: 'center',
                desktop: 'center',
            },
            footer: {
                mobile: 'center',
                tablet: 'center',
                desktop: 'left',
            },
        },
    },
};

export default theme;
