import React, { useRef, useEffect, useState } from 'react';
import * as FeatherIcon from 'react-feather';

import { CloseButton, Content, GlobalStyle, Overlay } from './ModalDialog.styles';

interface ModalProps {
    children: React.ReactNode;
    isOpen?: boolean;
    onClose?: () => void;
    closeOnOutsideClick?: boolean;
    width?: number;
}

const ModalDialog: React.FC<ModalProps> = ({ onClose, children, width, closeOnOutsideClick = true, isOpen = false }) => {
    const [visible, setVisible] = useState<boolean>(false);

    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
       setVisible(isOpen);

         if (isOpen) {
              document.body.classList.add('modal-open');
         } else {
              document.body.classList.remove('modal-open');
         }

    }, [isOpen]);

    const handleClose = () => {
        setVisible(false);
        
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 300); // delay to showing animation
    };

    const handleClickOutside = (event: React.MouseEvent) => {
        if (closeOnOutsideClick && modalRef.current && !modalRef.current.contains(event.target as Node)) {
           handleClose();
        }
    };

    return (
        <>
            <GlobalStyle />
            <Overlay isOpen={isOpen} visible={visible} onClick={handleClickOutside}>
                <Content ref={modalRef} visible={visible} width={width}>
                    <CloseButton onClick={handleClose}><FeatherIcon.X size={16} /></CloseButton>
                    {children}
                </Content>
            </Overlay>
        </>
    );
};

export default ModalDialog;
