import React from "react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

import { Typography } from "@/components/atoms";
import { StyledFlightDetailContainer, StyledFlightInfoContainer, StyledFlightInfoColumn, StyledFlightInfoCenter } from "./FlightItineraries.styles";

type Props = {
    icon: React.ReactNode;
    cityCode: string;
    cityName: string;
    datetime: string;
    carrierDetail?: string;
    duration?: string;
};

const FlightDetail: React.FC<Props> = ({ icon, cityCode, cityName, datetime, carrierDetail, duration }) => {
    const { t } = useTranslation();

    return (
    <StyledFlightDetailContainer>
        {icon}
        <StyledFlightInfoContainer>
            <StyledFlightInfoColumn>
                <Typography variant="cap" size='11px'>{cityCode}</Typography>
                <Typography variant="cap" size='11px'>
                    <Moment format="DD-MMM" parse="YYYY-MM-DDTHH:mm:ss" date={datetime} />
                </Typography>
                <Typography variant="cap" size='11px'>
                    <Moment format="HH:mm" parse="YYYY-MM-DDTHH:mm:ss" date={datetime} />
                </Typography>
            </StyledFlightInfoColumn>
            <StyledFlightInfoCenter>
                <Typography variant='cap' size='12px'>{cityName}</Typography>
                {duration && <Typography variant='cap' size='12px'>{t("flights:itinerary.duration", {duration})}</Typography>}
                {carrierDetail && <Typography variant='h6' size='12px' lineheight="20px">{carrierDetail}</Typography>}
            </StyledFlightInfoCenter>
        </StyledFlightInfoContainer>
    </StyledFlightDetailContainer>
)};

export default FlightDetail;