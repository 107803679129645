import React from 'react';
import { useTranslation } from 'next-i18next';
import { formatCurrency } from '@/utils/helpers';
import { useFlightsPrice } from '@/services/flights';
import { PriceResumeCard } from '@/components/molecules';
import { useWizard } from '@/modules/wizard';
import { useFilterSearch } from '@/modules/persistent-states';
import { KiuRouteType } from '@/graphql/types';
import { FlightDetailsProps } from './FlightDetails.type';
import { Wrapper, InnerWrapper } from './FlightDetails.styles';

const FlightDetails: React.FC<FlightDetailsProps> = ({ children, hideAcceptButton = false }) => {
    const { t, i18n } = useTranslation(); // Translations

    const [filterSearch] = useFilterSearch();

    const { state } = useWizard();

    const passengers: {
        number: number;
        type: string;
    }[] = [];

    let count = 1;
    let adultCount = 0;
    let childrenCount = 0;

    // Add adults to the list
    for (let i = 1; i <= filterSearch.passengers?.adults; i++) {
        passengers.push({ number: count, type: 'Adult' });
        adultCount++;
        count++;
    }

    // Add children to the list
    for (let i = 1; i <= filterSearch.passengers?.children; i++) {
        passengers.push({ number: count, type: 'Child' });
        childrenCount++;
        count++;
    }

    const [
        outboundDepartureDatetimes,
        outboundArrivalDatetimes,
        outboundOriginLocationCodes,
        outboundDestionationLocationCodes,
        returnDepartureDatetimes,
        returnArrivalDatetimes,
        returnOriginLocationCodes,
        returnDestionationLocationCodes,
        returnFlightNumbers,
        returnResBookDesigCodes,
        returnAirlineCodes
    ] = React.useMemo(() => {
        const outboundFlights = state.data?.outboundRouteSelected?.flights || [];
        
        const returnFlights = state.data?.returnRouteSelected?.flights || []

        return [
            outboundFlights.map((flight) => flight?.departingDatetime as string),
            outboundFlights.map((flight) => flight?.arrivingDatetime as string),
            outboundFlights.map((flight) => flight?.departingCity.code as string),
            outboundFlights.map((flight) => flight?.arrivingCity.code as string),
            returnFlights.map((flight) => flight?.departingDatetime as string),
            returnFlights.map((flight) => flight?.arrivingDatetime as string),
            returnFlights.map((flight) => flight?.departingCity.code as string),
            returnFlights.map((flight) => flight?.arrivingCity.code as string),
            returnFlights.map((flight) => flight?.flightNumber as string),
            (state.data?.returnRouteSelected?.categories || []) as string[],
            (state.data?.returnRouteSelected?.airlines || []) as string[],

        ];
    }, [state.data?.outboundRouteSelected?.flights, state.data?.returnRouteSelected?.flights, state.data?.returnRouteSelected?.categories, state.data?.returnRouteSelected?.airlines]);

    const { prices } = useFlightsPrice({
        outboundDepartureDatetimes: [...outboundDepartureDatetimes],
        outboundArrivalDatetimes: [...outboundArrivalDatetimes],
        outboundFlightNumbers: state.data?.outboundRouteSelected?.flights.map((flight) => flight?.flightNumber) as string[],
        outboundResBookDesigCodes: state.data?.outboundRouteSelected?.categories as string[],
        outboundAirlineCodes: state.data?.outboundRouteSelected?.airlines as string[],
        outboundOriginLocationCodes: [...outboundOriginLocationCodes],
        outboundDestinationLocationCodes: [...outboundDestionationLocationCodes],
        returnDepartureDatetimes: [...returnDepartureDatetimes],
        returnArrivalDatetimes: [...returnArrivalDatetimes],
        returnFlightNumbers: [...returnFlightNumbers],
        returnResBookDesigCodes: [...returnResBookDesigCodes],
        returnAirlineCodes: [...returnAirlineCodes],
        returnOriginLocationCodes: [...returnOriginLocationCodes],
        returnDestinationLocationCodes: [...returnDestionationLocationCodes],
        passengers: {
            adults: adultCount,
            children: childrenCount,
        },
    });

    const taxesByCode = prices?.response?.taxes?.map((tax) => ({
        name: tax?.code as string,
        value: formatCurrency(tax?.price?.amount as number, tax?.price?.currency as string, i18n.language),
    })) || [] as {
        name: string;
        value: string;
    }[]

    const costPerTraveler = prices?.response?.baseFare?.amount as number / (childrenCount + adultCount);

    return (
        <Wrapper>
            {React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                    passengers: passengers,
                    key: index,
                })
            )}
            <InnerWrapper>
                <PriceResumeCard
                    flights={[
                        state?.data?.outboundRouteSelected as KiuRouteType,
                        ...(state?.data?.returnRouteSelected ? [state?.data?.returnRouteSelected] : [])
                    ]}
                    airFare={[
                        ...(adultCount > 0 ? [
                            {
                                name: t('flights:resume.base-fare'),
                                value: `${adultCount} x ${formatCurrency(costPerTraveler, prices?.response?.baseFare.currency as string, i18n.language)}`,
                                type: "Adult"
                            },
                            {
                                name: t('flights:resume.subtotal'),
                                value: formatCurrency(costPerTraveler * adultCount, prices?.response?.baseFare.currency as string, i18n.language),
                                type: "Adult"
                            }
                        ] : []),
                        ...(childrenCount > 0 ? [
                            {
                                name: t('flights:resume.base-fare'),
                                value: `${childrenCount} x ${formatCurrency(costPerTraveler, prices?.response?.baseFare.currency as string, i18n.language)}`,
                                type: "Child"
                            },
                            {
                                name: t('flights:resume.subtotal'),
                                value: formatCurrency(costPerTraveler * childrenCount, prices?.response?.baseFare.currency as string, i18n.language),
                                type: "Child"
                            }
                        ] : [])
                    ]}
                    taxes={[
                        {
                            name: t('flights:resume.total'),
                            value: formatCurrency(prices?.response?.totalTaxes.amount as number, prices?.response?.totalTaxes.currency as string, i18n.language),
                        },
                        ...taxesByCode,
                    ]}
                    feeSummary={[
                        {
                            name: t('flights:resume.fee'),
                            value: formatCurrency(prices?.response?.fee.amount as number, prices?.response?.fee.currency as string, i18n.language),
                        },
                    ]}
                    total={formatCurrency(prices?.response?.total.amount as number, prices?.response?.total.currency as string, i18n.language)}
                    disabled={true}
                    hideButton={hideAcceptButton}
                    onAccept={() => {
                        // TODO: Agregar accion del boton
                        console.log('Accept and continue');
                    }}
                />
            </InnerWrapper>
        </Wrapper>
    );
};

export default FlightDetails;
